<template>
  <div>
    <div style="height: 100vh; background: #fff">
      <!--      头部区域-->
      <t-navbar :leftArrow="false" :rightShow="false">
        上传资源
        <template #right>
          <t-icon name="close" @click="onClosePressed"></t-icon>
        </template>
      </t-navbar>
      <!--      进度条区域-->
      <t-overlay :visible="isUploading">
        <div class="uploading">
          <div class="progress">
            <t-progress :percentage="uploadProgress"/>
          </div>
        </div>
      </t-overlay>
      <!--      制作区域-->
      <t-cell-group v-show="!isUploading" class="dialog-type-title">
        <t-cell title="资源名称" :note="itemData.name" @click="onNameEditPressed" arrow/>
        <t-cell title="资源图片">

          <UploadFile
              ref="uploadImage"
              accept="image/*"
              v-model="itemData.image"
              :albumId="albumData.u_id"
              type="image"
              @start="onUploadStart"
              @progress="onUploadProgress"
              @success="onUploadSuccess"
              @error="onUploadError"
              @finish="onUploadFinish"
          />
        </t-cell>
        <t-cell title="资源视频">
          <UploadFile
              ref="uploadVideo"
              accept="video/*"
              type="video"
              v-model="itemData.video"
              :albumId="albumData.u_id"
              @start="onUploadStart"
              @progress="onUploadProgress"
              @success="onUploadSuccess"
              @error="onUploadError"
              @finish="onUploadFinish"
          />
        </t-cell>
      </t-cell-group>
      <div style="padding-left: 0.2rem;padding-right: 0.2rem;">
        <t-button :disabled="submitting" size="large" variant="outline" block @click="onSetCoverPressed">设为封面
        </t-button>
        <t-button :disabled="submitting" size="large" variant="outline" block @click="onDeletePressed"
                  style="margin-top: 12px;">
          删除此项
        </t-button>
      </div>
      <!--      修改名称对话框-->
      <name-dialog ref="nameDialog"></name-dialog>
    </div>
  </div>
</template>

<script>

import UploadFile from "@/components/plugin/UploadFile";

import {Icon as TIcon} from 'tdesign-icons-vue-next'
import {deleteUpItem, updateUpItemInfo} from "@/api/api_item";
import {Message} from "tdesign-mobile-vue";
import NameDialog from "@/components/plugin/NameDialog";
import {updateUpGroupCover} from "@/api/api_group";

export default {
  name: "ItemUpload",
  components: {NameDialog, UploadFile, TIcon},
  data() {
    return {
      // OSS上传配置
      uploadOpts: {},
      // OSS客户端
      ossClient: {},
      // 订单信息
      orderData: {},
      // 相册数据
      albumData: {},
      // 当前编辑项数据
      itemData: {},
      // 是否在上传文件
      isUploading: false,
      // 上传进度
      uploadProgress: 0,
      submitting: false,
    }
  },
  created() {
    this.orderData = this.$store.orderData
    this.albumData = this.$store.currentAlbum
    this.itemData = this.$store.currentItem
    console.log('[ItemUpload]', 'created', this.itemData)
  },
  mounted() {
    console.log('[ItemUpload]', 'mounted')
    this.initUpload()
  },
  methods: {
    // 初始化上传逻辑
    initUpload() {
      // 初始化OSS客户端
      this.$store.initOSS().finally(() => {
        // 初始化 图片 上传参数
        this.$refs.uploadImage.init({ossClient: this.$store.ossClient, item: this.itemData})
        // 初始化 视频 上传参数
        this.$refs.uploadVideo.init({ossClient: this.$store.ossClient, item: this.itemData})
      })
    },
    // 关闭上传资源面板
    onClosePressed() {
      console.log('[ItemUpload]', 'onClosePressed')
      // TODO 标记数据变更后
      this.$router.go(-1)
    },
    // 修改名字被点击
    onNameEditPressed() {
      console.log('onNameEditPressed')
      this.$refs.nameDialog.setName(this.itemData.name).registerCallback((text, loadingStatus) => {
        console.log('text callback', text)
        if (text === this.itemData.name) {
          Message.warning({content: '资源名字未变更'})
          return
        }
        if (text && text.length > 0) {
          loadingStatus(true)
          updateUpItemInfo({itemId: this.itemData.u_id, name: text}).then(() => {
            Message.success({content: '修改资源名称成功'})
            this.itemData.name = text
            this.$refs.nameDialog.close()
          }).finally(() => {
            loadingStatus(false)
          })
        } else {
          Message.error({content: '无效的名称'})
        }
      }).show()
    },
    // 点击了设为封面
    onSetCoverPressed() {
      // console.log('onSetCoverPressed')
      if (this.itemData.image && this.itemData.image.length > 0) {
        if (this.albumData.cover === this.itemData.image) {
          Message.warning({content: '已设置当前图片为封面图'})
          return
        }
        this.submitting = true
        updateUpGroupCover(this.albumData.u_id, this.itemData.image).then(() => {
          // 修改内存数据
          this.albumData.cover = this.itemData.image
          Message.success({content: '设置封面图片成功'})
        }).finally(() => {
          this.submitting = false
        })
      } else {
        Message.warning({content: '图片数据不完整,无法设置成封面'})
      }
    },
    // 点击了删除
    onDeletePressed() {
      // console.log('onDeletePressed')
      this.$dialog.confirm({
        title: '确认删除',
        content: '资源删除后不可恢复,真的要删除此项?',
        confirmBtn: '删除',
        onConfirm: () => {
          deleteUpItem({itemId: this.itemData.u_id}).then(() => {
            Message.success({content: '删除资源成功'})
            this.$router.go(-1)
          })
        }
      })
    },
    // 视频上传进度
    onUploadStart() {
      console.log('onUploadStart')
      this.isUploading = true
    },
    // 视频上传进度
    onUploadProgress(progress) {
      console.log('onUploadProgress', progress)
      this.uploadProgress = progress
    },
    // 上传成功
    onUploadSuccess({type, path}) {
      console.log('onUploadSuccess', type, path)
      Message.success('上传成功')
    },
    // 上传失败
    onUploadError(err) {
      console.warn('onUploadError', err)
    },
    // 上传完成
    onUploadFinish() {
      console.log('onUploadFinish')
      this.isUploading = false
    },
  }
}
</script>

<style scoped>
.uploading {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100vw;
  height: 100vh;
}

.uploading .progress {
  width: 18rem;
  padding: 0.6rem;
}
</style>