<template>
  <div class="page">
    <!--    顶部导航栏-->
    <t-navbar :rightShow="false">
      数据组上传
      <template #right>
        <DeleteIcon @click="onDeletePressed"/>
        <CloudUploadIcon style="margin-left: 12px;" @click="onDonePressed"/>
      </template>
    </t-navbar>
    <!--    下拉菜单-->
    <t-pull-down-refresh v-model="isRefreshing" @refresh="onRefresh">
      <t-cell-group bordered>
        <t-cell title="数据组名称" :note="albumData.name" arrow @click="onNameEditPressed"/>
        <t-cell @click="onCreatePressed">
          <t-button id="btn-create"
                    size="large"
                    theme="primary" block
                    variant="outline">
            创建
          </t-button>
        </t-cell>
      </t-cell-group>
      <t-list :asyncLoading="loadStatus">
        <t-cell v-for="item in data.list" :key="item"
                :image="item.image.length>0?previewUrl(item.image):''"
                :title="item.name"
                :note="item.video.length>0?'有视频':''"
                :description="'更新时间:'+item.modifyTime"
                align="middle"
                @click="onRowPressed(item)"
                arrow>
        </t-cell>
        <template #footer>
          <div v-if="isLoadMore">
            <t-loading theme="dots"/>
          </div>
          <div v-else>
            <div v-if="data.total <= data.list.length" class="load-more">
              <div>已加载全部数据</div>
            </div>
            <div v-else class="load-more" @click.stop="onLoadMore">
              <div>点这里加载更多数据</div>
            </div>
          </div>
          <div v-if="loadStatus!=='loading' && data.total===0" class="error" @click.stop="onRefresh">
            <t-result style="margin-top: 20px;">
              <template #image>
                <ImageErrorIcon size="42px" style="color: #EF2D66;"/>
              </template>
              <template #title>
                <div>没有找到上传数据</div>
              </template>
              <template #description>
                <div>点击创建,可以增加上传数据</div>
              </template>
            </t-result>
          </div>
        </template>
      </t-list>
    </t-pull-down-refresh>
    <name-dialog ref="nameDialog"></name-dialog>
  </div>
</template>

<script>
import {ImageErrorIcon} from 'tdesign-icons-vue-next'
import {createUpItem, getUploadItemList} from '@/api/api_item'

import {closeToast, showToast} from "@/util/hud";
import NameDialog from "@/components/plugin/NameDialog";
import {Message} from "tdesign-mobile-vue";
import {endUpGroup, removeUpGroup, updateUpGroupInfo} from "@/api/api_group";
import {DeleteIcon, CloudUploadIcon} from 'tdesign-icons-vue-next';

export default {
  name: "AlbumUpload",
  components: {NameDialog, ImageErrorIcon, DeleteIcon, CloudUploadIcon},
  data() {
    return {
      // 订单数据
      orderData: {},
      // 相册数据
      albumData: {},
      // 是否在刷新
      isRefreshing: false,
      // 是否在加载更多
      isLoadMore: false,
      // 列表加载状态
      loadStatus: '',
      // 数据
      data: {
        page: 1,
        size: 10,
        total: 0,
        list: []
      },
    }
  },
  created() {
    // console.log('[AlbumUpload]', 'created')
    this.orderData = this.$store.orderData
    this.albumData = this.$store.currentAlbum
  },
  mounted() {
    // console.log('[AlbumUpload]', 'mounted')
    this.initUpload()
  },
  methods: {
    // 初始化上传逻辑
    initUpload() {
      // 初始化OSS客户端
      // this.$store.initOSS().finally(() => {
      // 初始完成后获取数据组列表
      this.onRefresh()
      // })
    },
    // 转换预览地址
    previewUrl(url) {
      return this.$store.convertPreviewUrl(url)
    },
    onDeletePressed() {
      // console.log('onDeletePressed')
      this.$dialog.confirm({
        title: '确认删除',
        content: '数据组删除后不可恢复,真的要删除此项?',
        confirmBtn: '删除',
        onConfirm: () => {
          removeUpGroup({groupId: this.albumData.u_id}).then(() => {
            Message.success({content: '删除数据组成功'})
            this.$router.go(-1)
          })
        }
      })
    },
    onDonePressed() {
      // console.log('onDonePressed')
      this.$dialog.confirm({
        title: '确认通知制作',
        content: '通知制作后,将不可以在此数据组继续上传资源,请确认',
        confirmBtn: '通知制作',
        onConfirm: () => {
          endUpGroup({groupId: this.albumData.u_id}).then(() => {
            Message.success({content: '已通知制作...'})
            this.onRefresh()
          })
        }
      })
    },
    // 重置页码和数量 - 刷新时用到
    resetPage() {
      this.data.page = 1
      this.data.size = 10
    },
    // 获取相册下的数据列表
    getAlbumItemList() {
      // 如果还在加载中,就忽略
      if (this.loadStatus === 'loading') {
        return
      }
      // 如果不是刷新模式, 页码数+1
      if (!this.isRefreshing) {
        this.data.page += 1
      } else {
        this.resetPage()
      }
      // 改为 加载中
      this.loadStatus = 'loading'
      // 获取数据
      getUploadItemList({groupId: this.albumData.u_id, page: this.data.page, size: this.data.size}).then(res => {
        // 非刷新模式使用数据拼接的方式显示
        this.data.total = res.total
        if (!this.isRefreshing) {
          this.data.list = this.data.list.concat(res.list)
        } else {
          this.data.list = res.list
        }
      }).finally(() => {
        this.loadStatus = 'load-more'
        this.isRefreshing = false
        this.isLoadMore = false
      })
    },
    // 创建新的数据项
    onCreatePressed() {
      console.log('onCreatePressed')
      showToast({message: '创建中...'})
      createUpItem({groupId: this.albumData.u_id}).then(() => {
        this.onRefresh()
      }).finally(() => {
        closeToast()
      })
    },
    // 修改名字被点击
    onNameEditPressed() {
      console.log('onNameEditPressed')
      this.$refs.nameDialog.setName(this.albumData.name).registerCallback((text, loadingStatus) => {
        console.log('text callback', text)
        if (text === this.albumData.name) {
          Message.warning({content: '数据组名字没有变化'})
          return
        }
        if (text && text.length > 0) {
          loadingStatus(true)
          updateUpGroupInfo({groupId: this.albumData.u_id, name: text}).then(() => {
            Message.success({content: '修改数据组名称成功'})
            this.albumData.name = text
            this.$refs.nameDialog.close()
          }).finally(() => {
            loadingStatus(false)
          })
        } else {
          Message.error({content: '无效的名称'})
        }
      }).show()
    },
    // 刷新数据
    onRefresh() {
      this.isRefreshing = true
      this.isLoadMore = false
      this.getAlbumItemList()
    },
    // 加载更多
    onLoadMore() {
      this.isRefreshing = false
      this.isLoadMore = true
      this.getAlbumItemList()
    },
    // 行点击, 打开上传面板
    onRowPressed(item) {
      console.log('onRowPressed', item)
      showToast({message: '点击了'})
      // 设置当前的激活项
      this.$store.setCurrentItem(item)
      this.$router.push('/item')
    },
  }
}
</script>

<style scoped>
.page {
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

#btn-create {
  color: #EF2D66 !important;
  border: 1px solid #EF2D66;
}

.load-more {
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 0.8rem;
  opacity: 0.8;
  background-color: whitesmoke;
}

.t-pull-down-refresh {
  min-height: calc(100vh - 44px);
}
</style>