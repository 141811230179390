<template>
  <router-view/>
</template>

<script>


export default {
  name: 'App',
  components: {},
  created() {
    // console.log('[App]', 'created')
  },
  mounted() {
    // console.log('[App]', 'mounted')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*text-align: center;*/
  /*margin-top: 60px;*/
}

body {
  margin: 0;
  padding: 0;
}
</style>
