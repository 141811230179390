<template>
  <t-popup v-model="isShow" placement="top">
    <div style="width:100vw; height: 200px; background: #fff;">
      <!--      头部区域-->
      <t-navbar :leftArrow="false" :rightShow="false">
        修改名称
      </t-navbar>
      <div style="padding: 24px;">
        <t-input :disabled="loading" v-model="name" placeholder="请输入新名称"/>
        <t-button :loading="loading" size="large" theme="primary" block style="margin-top: 24px;" @click="onConfirm">
          保存
        </t-button>
      </div>
    </div>
  </t-popup>
</template>

<script>

export default {
  name: "NameDialog",
  data() {
    return {
      callback: undefined,
      isShow: false,
      loading: false,
      name: ''
    }
  },
  created() {
    console.log('[NameDialog]', 'created')
  },
  mounted() {
    console.log('[NameDialog]', 'mounted')
  },
  methods: {
    setName(name) {
      this.name = name
      return this
    },
    registerCallback(cb) {
      console.log('[NameDialog]', 'registerCallback')
      this.callback = cb
      return this
    },
    show() {
      console.log('[NameDialog]', 'show')
      this.isShow = true
    },
    close() {
      console.log('[NameDialog]', 'close')
      this.isShow = false
    },
    onConfirm() {
      console.log('[NameDialog]', 'onConfirm')
      this.callback(this.name, this.loadingStatus)
    },
    loadingStatus(status) {
      this.loading = status
    }
  }
}
</script>

<style scoped>

</style>