import request from "@/api/request";
import sha1 from 'js-sha1'
import md5 from 'js-md5'

// 使用订单状态进行登入
export function auth(data) {
    const contact = data.contact
    // console.log('原始登录数据', contact)
    let entry = sha1(contact)
    // console.log('sha1登录数据', JSON.stringify(entry))
    entry = md5(entry)
    // console.log('md5登录数据', entry)
    const param = {
        orderId: data.orderId,
        contact: entry,
        timestamp: new Date().getTime()
    }
    return request({
        url: 'auth',
        method: 'post',
        data: param
    })
}

// 获取订单状态和信息
export function info() {
    return request({
        url: 'info',
        method: 'get'
    })
}

// 锁定订单
export function lockOrder(param) {
    return request({
        url: 'lock',
        method: 'post',
        data: {lock: param}
    })
}