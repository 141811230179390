// 引入Pinia
import {createPinia} from 'pinia'
import {defineStore} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import {info} from "@/api/api_order";
import OSS from "ali-oss";
import {closeToast, showToast} from "@/util/hud";
import {getUploadInfo} from "@/api/api_item";

// 创建 Pinia 实例
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);

// 注册全局可调用存储库
export function registerPinia(app) {
    app.use(pinia)
    const store = orderStore();
    app.config.globalProperties.$store = store
    // app.use(store)
}

export const orderStore = defineStore('order', {
    // 类似于Vue2组件中的data，用于存储全局状态数据，但有两个要求
    // 1. 必须是函数，目的是为了在服务端渲染的时候避免交叉请求导致的数据状态污染
    // 2. 必须是箭头函数，这样是为了更好的 TS 类型推导
    state: () => {
        return {
            ossClient: undefined,
            // 登入后的ID
            orderId: '',
            // 登入后的数据
            orderData: '',
            // 登入后的Token
            token: '',
            // 当前编辑的相册数据
            currentAlbum: {},
            // 当前编辑的数据项
            currentItem: {}
        }
    },
    // 开启数据缓存
    persist: {
        enabled: true,
    },
    getters: {},
    actions: {
        // 初始化OSS客户端
        initOSS() {
            return new Promise((resolve, reject) => {
                showToast({message: '初始化中...'})
                getUploadInfo().then(res => {
                    // 初始化OSS客户端
                    this.ossClient = OSS(res)
                    resolve(this.ossClient)
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    closeToast()
                })
            })
        },
        // 加签URL地址
        convertPreviewUrl(url) {
            return this.ossClient?.signatureUrl(url);
        },
        //更新订单Id
        setOrderId(orderId) {
            this.orderId = orderId
        },
        //
        setToken(token) {
            this.token = token
        },
        removeToken() {
            this.token = ''
        },
        setCurrentAlbum(album) {
            this.currentAlbum = album
        },
        setCurrentItem(item) {
            this.currentItem = item
        },
        // 获取订单信息
        getOrderInfo() {
            return new Promise((resolve, reject) => {
                info().then(res => {
                    // console.log('getOrderInfo resp', res)
                    this.orderData = res
                    resolve(res)
                }).catch(err => {
                    // console.error('getOrderInfo err', err)
                    reject(err)
                })
            })
        }
    }
})