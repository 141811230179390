import {createApp} from 'vue'
import App from './App.vue'
import TDesign from 'tdesign-mobile-vue';
// 引入router
import router from "@/router";
import {registerPinia} from "@/store";

const app = createApp(App)
app.use(TDesign)
app.use(router)
registerPinia(app)
app.mount('#app')

