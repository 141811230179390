// 提取文件扩展名
export function file_ext_name(filename) {
    if (filename.indexOf('.') !== -1) {
        const arr = filename.split('.')
        const len = arr.length
        if (len > 0) {
            return arr[len - 1]
        } else {
            return ''
        }
    } else {
        return ''
    }
}

// Base64 转 File
export function base64ToFile(base64, fileName) {
    let arr = base64.split(','), type = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type});
}