import request from "@/api/request";

// 获取当前订单下的所有上传组列表
export function getUpGroupList(data) {
    return request({
        url: '/gp/list',
        method: 'post',
        data
    })
}

// 创建上传组
export function createUpGroup(data) {
    return request({
        url: '/gp/create',
        method: 'post',
        data
    })
}

// 修改上传组封面
export function updateUpGroupCover(groupId, coverUrl) {
    return request({
        url: '/gp/update',
        method: 'post',
        data: {
            groupId: groupId,
            cover: coverUrl
        }
    })
}

// 更新上传组
export function updateUpGroupInfo(data) {
    return request({
        url: '/gp/update',
        method: 'post',
        data
    })
}

// 结束上传组
export function endUpGroup(data) {
    return request({
        url: '/gp/end',
        method: 'post',
        data
    })
}

// 删除上传组
export function removeUpGroup(data) {
    return request({
        url: '/gp/remove',
        method: 'post',
        data
    })
}
