<template>
  <div class="login flex align-center margin-right">
    <t-cell-group title="Miao Up! Up采集系统!">
      <t-input v-model="orderId" placeholder="填写订单编号" :disabled="isLoading"/>
      <t-input v-model="contact" placeholder="填写收货手机" :disabled="isLoading"/>
      <t-button class="button"
                size="large"
                theme="primary"
                shape="round"
                :loading="isLoading"
                block
                @click="onNextPressed">
        去制作
      </t-button>
    </t-cell-group>
    <t-message v-model="showAlert" theme="warning" @update="showAlert">{{ hint }}</t-message>
  </div>
</template>

<script>
import {auth} from "@/api/api_order";
import {Message} from "tdesign-mobile-vue";
import {parseValueCaesar} from "@/util/caesar";

export default {
  name: "OrderAuth",
  components: {},
  data() {
    return {
      orderId: '',
      contact: '',
      isLoading: false,
      showAlert: false,
      hint: ''
    }
  },
  created() {
    const conf = this.$route?.query?.conf
    if (conf && conf.length > 1) {
      console.log('发现自配置参数')
      try {
        const raw = parseValueCaesar(conf)
        const data = JSON.parse(raw)
        this.orderId = data.orderId
        this.contact = data.contact
      } catch {
        Message.warning('解析配置失败，请手动输入订单编号')
      }
    }
  },
  mounted() {
    if (this.orderId.length > 3 && this.contact.length > 3) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.onNextPressed()
        }, 500)
      })
    }
  },
  methods: {
    onNextPressed() {
      // console.log('onNextPressed', this.orderId, this.contact, this.isLoading)
      if (this.orderId) {
        // console.log('order length', this.orderId, this.orderId.length)
        const len = this.orderId.length
        if (len >= 8 && len <= 50) {
          // console.log('订单编号检查通过')
        } else {
          this.hint = '订单编号不正确,请检查后重新填写'
          this.showAlert = true
          return
        }
      } else {
        this.hint = '必须填写订单编号'
        this.showAlert = true
        return
      }
      if (this.contact) {
        // console.log('contact length', this.contact, this.contact.length)
        const len = this.orderId.length
        if (len >= 3 && len <= 15) {
          // console.log('收件手机检查通过')
        } else {
          this.hint = '收件手机不正确,请检查后重新填写'
          this.showAlert = true
          return
        }
      } else {
        this.hint = '必须填写收件手机'
        this.showAlert = true
        return
      }
      this.isLoading = true
      // 执行登录操作
      auth({
        orderId: this.orderId,
        contact: this.contact
      }).then(res => {
        // console.log('auth resp', res)
        const token = res.token
        this.$store.setOrderId(this.orderId)
        this.$store.setToken(token)
        this.$router.replace('/main')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
.login {
  /*margin-left: 12px;*/
  /*margin-right: 12px;*/
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  align-content: center;
  justify-content: center;
}

.button {
  /*width: 80vw !important;*/
  margin-top: 12px;
  /*margin-left: 12px;*/
  /*margin-right: 12px;*/
}
</style>