<template>
  <div class="upload-container" @click="onUploadPressed">
    <div v-if="type === 'image'">
      <t-image v-if="item.image.length>0" class="preview" :src="convertPreviewUrl(item.image)" :alt="item.name"
               fit="fill"
               lazy></t-image>
      <UploadIcon v-else class="icon" size="32"/>
    </div>
    <div v-else-if="type === 'video'">
      <video v-if="item.video.length>0" class="preview" :src="convertPreviewUrl(item.video)" :alt="item.name" muted/>
      <UploadIcon v-else class="icon" size="32"/>
    </div>
    <div v-else class="preview">
      不支持的媒体类型
    </div>
    <input ref="fileUpload"
           type="file"
           :accept="accept"
           class="input-button"
           @change="handleChange"
           @click="handelClick"
    />
  </div>
</template>

<script>
import {UploadIcon} from 'tdesign-icons-vue-next'
import {file_ext_name} from "@/util/file";
import {Message} from "tdesign-mobile-vue";
import {updateUpItemInfo} from "@/api/api_item";
import {showToast, closeToast} from "@/util/hud";

export default {
  name: "UploadFile",
  components: {UploadIcon},
  props: {
    type: {type: String},
    accept: {type: String},
    albumId: {type: String},
  },
  emits: ['start', 'progress', 'success', 'finish'],
  data() {
    return {
      ossClient: undefined,
      itemId: '',
      item: {
        u_id: '',
        name: '',
        image: '',
        video: ''
      }
    }
  },
  created() {
    // console.log('[UploadFile]', 'created')
    // console.log('accept', this.accept)
    // console.log('albumId', this.albumId)
  },
  mounted() {
    // console.log('mounted')
  },
  methods: {
    // 初始化数据
    init({ossClient, item}) {
      this.ossClient = ossClient
      this.item = item
      this.itemId = item?.u_id
      // console.log('init upload', this.ossClient, this.albumId, this.itemId)
    },
    // 签名预览地址
    convertPreviewUrl(url) {
      return this.ossClient?.signatureUrl(url);
    },
    onUploadPressed() {
      console.log('onUploadPressed')
      // 不在上传状态才允许选择文件
      if (!this.isUploading) {
        this.$refs.fileUpload.click()
      } else {
        Message.warning({content: '正在上传中'})
      }
    },
    handelClick(e) {
      console.log('handelClick', e)
    },
    handleChange(e) {
      console.log('handleChange', e, e.target.files)
      const files = e.target.files
      if (files.length > 0) {
        const file = files[0]
        this.$emit('start')
        // 显示上传提示框
        showToast({message: '上传中'})
        // 重置DOM值，防止下次上传无响应
        e.target.value = ''
        // 上传文件
        this.UploadFile(file, pgs => {
          this.$emit('progress', pgs)
        }).then((res) => {
          console.log('上传文件成功')
          this.targetResAdd(res.file, res.path)
          if (this.type === 'image') {
            this.item.image = res.path
          } else if (this.type === 'video') {
            this.item.video = res.path
          }
        }).catch(err => {
          console.error('上传文件失败', err)
          Message.warning(err)
        }).finally(() => {
          this.isUploading = false
          // 隐藏上传提示框
          closeToast()
        })
      } else {
        console.warn('没有选择文件')
      }
    },
    UploadFile(file, pgs) {
      console.log('UploadFile', file)
      // 提取文件扩展名
      const file_ext = file_ext_name(file.name)
      // 构造文件名
      const fileName = `${this.ossClient.options.url}/${this.albumId}/${this.itemId}.${file_ext}`
      // 上传参数构建
      const progress = async (p) => {
        // this.currentCheckpoint = checkpoint
        const value = Math.floor(p * 100)
        pgs(value)
      }
      const options = {
        progress,
        partSize: 100 * 1024
      }
      this.isUploading = true
      return new Promise((resolve, reject) => {
        console.log('file', file, typeof (file))
        this.ossClient.multipartUpload(fileName, file, options).then(({name, res}) => {
          if (res && res.status === 200) {
            const uploadName = file.name
            const path = name
            console.log(`OSS上传成功:`, uploadName, path)
            resolve({isOK: true, status: 'success', file: file, path})
          } else {
            const msg = '上传失败,返回错误代码:' + res.status
            console.warn(msg)
            Message.error(msg)
            reject({isOK: false, status: 'fail', error: msg})
          }
        }).catch((err) => {
          const msg = '上传失败,失败原因:' + (err?.message || err)
          reject({isOK: false, status: 'fail', error: msg})
        })
      })
    },
    // 标记资源上传
    targetResAdd(file, path) {
      if (this.accept === 'video/*') {
        updateUpItemInfo({
          itemId: this.itemId,
          video: path,
          file_name_v: file.name,
          file_size_v: file.size
        }).then(() => {
          this.$emit('success', {type: this.accept, path: path})
        }).finally(() => {
          this.$emit('finish')
        })
      } else {
        updateUpItemInfo({
          itemId: this.itemId,
          image: path,
          file_name_i: file.name,
          file_size_i: file.size
        }).then(() => {
          this.$emit('success', {type: this.accept, path: path})
        }).finally(() => {
          this.$emit('finish')
        })
      }
    },
  }

}
</script>

<style scoped>

.upload-container {
  background-color: aliceblue;
  width: 30vw;
  height: 30vw;
  line-height: 30vw;
  text-align: center;
}

.upload-container .icon {
  color: #EF2D66;
}

.input-button {
  position: relative;
  display: inline-block;
  visibility: hidden;
}

.preview {
  width: 30vw;
  height: 30vw;
  line-height: 30vw;
}

</style>