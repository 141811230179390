import {Base64} from "js-base64";

// 凯撒密码加密
export function makeValueCaesar(val, ts) {
    // console.log('makeValueCaesar', val, ts)
    const items = val.split('')
    // 动态偏移
    const keyArr = ts.split('')
    // 偏移长度
    const keyLen = keyArr.length
    // 偏移数据
    const keys = keyArr.map(Number)
    // 需要输出的偏移数据
    const keysMask = keyArr.map((item, index) => {
        return new Number(item) + index + keyLen
    }).reverse()
    // 拼接出加密数据
    const data = items.map((item, index) => {
        const keyIndex = index < keyLen ? index : index % keyLen
        const charAt = item.charCodeAt(0)
        item = charAt + keys[keyIndex]
        return item
    })
    const payload = data.concat(keysMask)
    const offset = keys.length
    return Base64.encode(JSON.stringify({p: payload, o: offset}), true)
}

// 凯撒密码解密
export function parseValueCaesar(val) {
    // console.log('parseValueCaesar', val)
    // 解Base64
    const json = Base64.decode(val)
    // 解JSON
    const {p: payload, o: offset} = JSON.parse(json)
    // 解偏移区域
    const keysMask = payload.slice(-offset)
    const keyLen = keysMask.length
    // 解真实偏移
    const keys = keysMask.reverse().map((key, index) => {
        return new Number(key) - index - keyLen
    })
    // 解数据区域
    const data = payload.slice(0, -offset)
    // 恢复数据
    const items = data.map((item, index) => {
        const keyIndex = index < keyLen ? index : index % keyLen
        item = item - keys[keyIndex]
        item = String.fromCharCode(item)
        return item
    }).join('')
    return items
}
