<template>
  <div>
    <t-navbar @click-text="onClickTitle" :leftArrow="false" :rightShow="false">
      Miao Up!Up!采集系统
      <template #right>
        <t-icon name="close" @click="onClosePressed"></t-icon>
      </template>
    </t-navbar>
    <div>
      <t-pull-down-refresh v-model="isRefreshing" @refresh="onRefresh">
        <t-cell-group title="基础信息" bordered>
          <t-cell title="订单编号" :note="orderId" @click="onOrderIdPressed"/>
          <t-cell title="订单权限" :description="'还可以创建'+canCreateRemain()+'组上传数据'"
          >
            <t-button id="btn-create"
                      size="large"
                      theme="primary" block
                      variant="outline"
                      @click="onCreatePressed">
              创建
            </t-button>
          </t-cell>
        </t-cell-group>
        <t-cell-group title="数据列表" bordered>
          <t-list :loading="loadStatus">
            <t-cell v-for="item in data.list" :key="item"
                    :image="item.cover.length>0?previewUrl(item.cover):''"
                    :title="item.name"
                    :description="'更新时间:'+item.modifyTime"
                    align="middle"
                    @click="onRowPressed(item)"
                    arrow>
            </t-cell>
            <template #footer>
              <div v-if="isLoadMore">
                <t-loading theme="dots"/>
              </div>
              <div v-else>
                <div v-if="data.total <= data.list.length" class="load-more">
                  <div>已加载全部数据</div>
                </div>
                <div v-else class="load-more" @click.stop="onLoadMore">
                  <div>点这里加载更多数据</div>
                </div>
              </div>
            </template>
          </t-list>
        </t-cell-group>
      </t-pull-down-refresh>
    </div>
  </div>
</template>

<script>
import {Icon as TIcon} from 'tdesign-icons-vue-next'
import {Message} from "tdesign-mobile-vue";
import {Toast} from 'tdesign-mobile-vue';
import {createUpGroup, getUpGroupList} from "@/api/api_group";
import {closeToast, showToast} from "@/util/hud";

export default {
  name: "OrderMain",
  components: {TIcon},
  data() {
    return {
      // 是否刷新中
      isRefreshing: false,
      // 是否加载更多中
      isLoadMore: false,
      // 列表加载状态 loading;load-more
      loadStatus: '',
      // 订单ID
      orderId: '',
      // 订单数据
      orderData: {},
      // 列表数据
      data: {
        page: 1,
        size: 10,
        total: 0,
        list: []
      },
    }
  },
  computed: {
    // 是否可以创建相册
    canCreateAlbum() {
      // console.log('canCreateAlbum', this.data.total < this.orderData.limit, this.data.total, this.orderData.limit)
      return this.data.total < this.orderData.limit
    },

  },
  created() {
    this.orderId = this.$store.orderId
    this.orderData = this.$store.orderData
    // console.log('[Main]', 'Created', this.orderId, this.orderData)
  },
  mounted() {
    this.initUpload()
  },
  methods: {
    // 初始化上传逻辑
    initUpload() {
      // 初始化OSS客户端
      this.$store.initOSS().finally(() => {
        // 初始完成后获取数据组列表
        this.onRefresh()
      })
    },
    // 转换预览地址
    previewUrl(url) {
      // console.log('previewUrl', url)
      // url += '?x-oss-process=image/resize,m_fill,h_100,w_100';
      let ossUrl = this.$store.convertPreviewUrl(url)
      // console.log('ossUrl', ossUrl)
      return ossUrl
    },
    // 剩余可创建相册数
    canCreateRemain() {
      return this.orderData.limit - this.data.total
    },
    // 创建新的一组数据
    onCreatePressed() {
      console.log('onCreatePressed')
      if (this.data.total >= this.orderData.limit) {
        Message.error({content: '超过限制数量,请联系客服增加权限'})
        return
      }
      showToast({message: '创建中...'})
      createUpGroup().then(() => {
        Message.success({content: '创建成功'})
        this.resetPage()
        this.getAlbumListData()
      }).finally(() => {
        closeToast()
      })
    },
    // 重设页码
    resetPage() {
      this.data.page = 1
      this.data.size = 10
    },
    // 获取授权制作相册列表
    getAlbumListData() {
      // 如果还在加载中,就忽略
      if (this.loadStatus === 'loading') {
        return
      }
      // 如果不是刷新模式, 页码数+1
      if (!this.isRefreshing) {
        this.data.page += 1
      } else {
        this.resetPage()
      }
      // 改为 加载中
      this.loadStatus = 'loading'
      // 获取数据
      getUpGroupList({
        page: this.data.page,
        size: this.data.size
      }).then(res => {
        // 非刷新模式使用数据拼接的方式显示
        this.data.total = res.total
        if (!this.isRefreshing) {
          this.data.list = this.data.list.concat(res.list)
        } else {
          this.data.list = res.list
        }
      }).finally(() => {
        this.loadStatus = 'load-more'
        this.isRefreshing = false
        this.isLoadMore = false
      })
    },
    // 点击标题栏
    onClickTitle() {
      // console.log('[Main]', 'onClickTitle')
      const hint = new Date().getTime() % 2 === 0 ? '咕~' : '呱~'
      Toast.success({
        message: hint,
        duration: 500,
      })
    },
    onClosePressed() {
      // console.log('[Main]', 'onClosePressed')
      this.$dialog.confirm({
        title: '确认退出',
        content: '确认要退出当前账户?',
        confirmBtn: '退出',
        onConfirm: () => {
          this.$store.removeToken()
          this.$router.replace('/')
        }
      })
    },
    onOrderIdPressed() {
      // console.log('[Main]', '粘贴板 复制')
      navigator.clipboard.writeText(this.orderId).then(() => {
        this.$message.success('复制订单编号成功')
      })
    },
    // 刷新数据
    onRefresh() {
      this.isRefreshing = true
      this.isLoadMore = false
      this.getAlbumListData()
    },
    // 加载更多
    onLoadMore() {
      this.isRefreshing = false
      this.isLoadMore = true
      this.getAlbumListData()
    },
    // 行点击
    onRowPressed(item) {
      console.log('[Main]', 'onRowPressed', item)
      this.$store.setCurrentAlbum(item)
      this.$router.push('/album')
    }
  }
}
</script>

<style scoped>
#btn-create {
  color: #EF2D66 !important;
  border: 1px solid #EF2D66;
}

.load-more {
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 0.8rem;
  opacity: 0.8;
  background-color: whitesmoke;
}

.t-pull-down-refresh {
  min-height: calc(100vh - 44px);
}
</style>