import {Toast} from 'tdesign-mobile-vue';
// 封装加载器
export default class HUD {
    constructor(opts) {
        showToast(opts)
    }
}

export function showToast(opts) {
    // 不透传数据
    opts['preventScrollThrough'] = true
    // 显示阻断层
    opts['showOverlay'] = true
    // 加载框样式
    opts['theme'] = 'loading'
    // 消失时间设置为最大，就可以当做全局提醒来用了
    opts['duration'] = Number.MAX_VALUE
    // 不带图标
    opts['icon'] = 'none'
    Toast.loading(opts)
}

export function closeToast() {
    Toast.clear()
}
